<template>
  <div class="menu">
    <ul class="menu__list" v-if="!isProcessing">
      <div class="menu__list__item--inline" v-if="user">
        <v-img class="menu__list__item__icon" :src="user.icon"
               @click="$router.push({ name: 'profile', params: { uid: uid } })" />
        <p class="menu__list__item__info"
           @click="$router.push({ name: 'profile', params: { uid: uid } })">{{ user.name }} </p>
        <v-btn class="menu__list__item__edit" @click="$router.push({ name: 'profile_edit' })" outlined>プロフィール編集</v-btn>
      </div>
      <li class="menu__list__item" v-if="faqs.length">
        <router-link class="menu__list__item__text" :to="{ name: 'faq' }">よくある質問（FAQ）</router-link>
      </li>
      <li class="menu__list__item" v-if="!isExemption">
        <h2 class="menu__list__item__title">お支払い情報</h2>
        <router-link class="menu__list__item__text" :to="{ name: 'payment_plan' }">登録中のプラン</router-link>
        <router-link class="menu__list__item__text" :to="{ name: 'payment_card_change' }">カード情報の変更</router-link>
      </li>
      <li class="menu__list__item">
        <h2 class="menu__list__item__title">本アプリについて</h2>
        <router-link class="menu__list__item__text" :to="{ name: 'terms' }">利用規約</router-link>
        <router-link class="menu__list__item__text" :to="{ name: 'policy' }">プライバシーポリシー</router-link>
        <router-link class="menu__list__item__text" :to="{ name: 'law' }">特定商取引法に基づく表記</router-link>
      </li>
      <li class="menu__list__item">
        <a class="menu__list__item__text" target="_blank" rel="noopener noreferrer" v-if="isContactURL" :href="contact">お問い合わせ</a>
        <a class="menu__list__item__text" target="_blank" v-else
           :href="'mailto:' + contact + '?subject=' + this.serviceName + 'に関するお問い合わせ'">お問い合わせ</a>
      </li>
      <li class="menu__list__item">
        <a class="menu__list__item__text" target="_blank" rel="noopener noreferrer"
           :href="this.companyURL">運営</a>
      </li>
      <li class="menu__list__item">
        <p class="menu__list__item__text" @click="signout()">ログアウト</p>
      </li>
      <li class="menu__list__item">
        <router-link class="menu__list__item__text" :to="{ name: 'cancel' }">退会</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import url from '@/assets/lib/url'

export default {
  mixins: [url],
  async mounted () {
    const promises = []
    // 自分自身が例外ユーザーかどうかの確認
    const isGotExemption = this.$store.getters['exemptions/isGot']
    if (!isGotExemption) promises.push(this.$store.dispatch('exemptions/getExemption', this.uid))

    // 設定の基本情報が存在しない場合は取得
    const settingKey = ['serviceName', 'companyURL', 'contact']
    for (const key of settingKey) {
      if (!this[key]) promises.push(this.$store.dispatch('settingGeneral/getSettingGeneral', key))
    }

    // 限定グループ名を取得していない場合、取得する
    if (!this.groupName) promises.push(this.$store.dispatch('settingGroup/getGroupSetting', 'groupName'))

    // FAQが未取得なら取得
    if (this.faqs.length === 0) promises.push(this.$store.dispatch('faqs/getFaqs'))

    await Promise.all(promises)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Object} ユーザー
     */
    user () {
      return this.$store.getters['users/user'](this.uid)
    },
    /**
     * @return {Boolean} 自分自身が例外ユーザーかどうか
     */
    isExemption () {
      const exemption = this.$store.getters['exemptions/exemption']
      return !!exemption
    },
    /**
     * @return {String} サービス名
     */
    serviceName () {
      const data = this.$store.getters['settingGeneral/settingGeneral']('serviceName')
      return data ? data.value : null
    },
    /**
     * @return {String} 限定グループ名
     */
    groupName () {
      return this.$store.getters['settingGroup/groupName']
    },
    /**
     * @return {String} 運営のURL
     */
    companyURL () {
      const data = this.$store.getters['settingGeneral/settingGeneral']('companyURL')
      return data ? data.value : null
    },
    /**
     * @return {Boolean} お問い合わせ先がURLかどうか
     */
    isContactURL () {
      return this.isValidURL(this.contact)
    },
    /**
     * @return {String} お問い合わせ先（メールアドレス or URL）
     */
    contact () {
      const data = this.$store.getters['settingGeneral/settingGeneral']('contact')
      return data ? data.value : null
    },
    /**
     * @return {Object[]} faq情報
     */
    faqs () {
      return this.$store.getters['faqs/faqs']
    }
  },
  methods: {
    /**
     * サインアウト処理
     */
    signout () {
      this.$store.commit('setSubmitting', true)
      this.$store.dispatch('auth/signout').then(result => {
        if (result.status === 'error') {
          this.$store.commit('setTelop', { show: true, msg: 'ログアウトができませんでした。時間をおいて再度試してみてください。', type: 'error' })
          this.$store.commit('setSubmitting', false)
          return 0
        }

        this.$router.push({ name: 'top' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.menu {
  width: 100vw;
  max-width: $max_width;
  margin: $header_height auto $padding_height;
  &__list {
    padding: 0;
    margin: 0;
    &__item {
      width: 100%;
      padding: $padding_height $padding_width;
      list-style: none;
      border-bottom: 1px solid $gray_color;
      &--inline {
        @extend .menu__list__item;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &__icon {
        flex: initial;
        width: 38px;
        height: 38px;
        background-color: $gray_lighten_color;
        border-radius: 50%;
      }
      &__info {
        flex: 1;
        margin: 0 0 0 15px;
        font-size: 1.4rem;
      }
      &__edit {
        margin-left: 10px;
        font-size: 1.2rem;
        color: $concept_color !important;
        text-align: center;
        border: 1px solid $concept_color;
        border-radius: 15px;
        &.v-btn:not(.v-btn--round).v-size--default {
          height: auto;
          padding: 8px 10px;
        }
      }
      &__title {
        margin: 0 0 10px;
        font-size: 1.2rem;
        color: $concept_color;
      }
      &__text {
        display: block;
        margin: 0 0 10px 22px;
        font-size: 1.4rem;
        line-height: 2rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
